import Routes from "./routes";
import React from "react";

function App() {
  return (
     <Routes/>
  );
}

export default App;
